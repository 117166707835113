.accordion-section {
    display: flex;
    flex-direction: column;
}

.accordion-title {
    background:  linear-gradient(to right,  rgba(246,50,62,0.90) ,rgba(246,50,62,0.90), rgba(246,50,62,0.74),rgba(102, 21, 21, 0));
    color: #FFFFFF;
    borderColor: -moz-linear-gradient(45deg,rgba(232,28,28,0.90), rgba(102, 21, 21, 0));
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 6px;
    outline-style: none;
    transition: padding-left 0.5s ease;
    margin-top: 15px;
    padding: 10px;


    &:hover {
        padding-left: 20px;
        background:  linear-gradient(to right,  rgba(246,50,62,0.90) ,rgba(246,50,62,0.90), rgba(246,50,62,0.74),#4304c4);
        borderColor: linear-gradient(45deg,rgba(232,28,28,0.90), rgba(102, 21, 21, 0));
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .2), 0 13px 24px -11px #ffd6cb;

    }
}


.accordion-icon {
    margin-left: auto;
    transition: transform 0.6s ease;
    color: #000000;

}

.rotate {
    transform: rotate(90deg);
}

.accordion-content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
}
